const getLinkBase = require('@bottlebooks/gatsby-theme-event/src/getLink');

module.exports = function getLink(context) {
  const link = getLinkBase(context);
  const brellaBase = 'https://next.brella.io/events/BuyCalifornia';
  return {
    ...link,

    //  The Brella links.
    brella: {
      exhibitor: ({ brellaId }) => `${brellaBase}/sponsors/${brellaId}`,
      people: () => `${brellaBase}/people`,
      sessions: () => `${brellaBase}/schedule`,
    },
  };
};
